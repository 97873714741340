@import "./variables.scss";

.hero-images-container {
  height: 376px;

  @media screen and (min-width: $md) {
    height: 400px;
  }

  @media screen and (min-width: $lg) {
    height: 550px;

    .hero-image {
      width: 21%;
      min-width: 21%;
      max-width: none;
      transition: width 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
      &:hover {
        width: 100%;
      }
    }

    .is-hover {
      width: 58%;
    }
  }

  .hero-image-mobile {
    width: 240px;
    @media screen and (min-width: $md) {
      width: 300px;
    }
  }

  .swiper-slide {
    width: auto;
  }
}
