$bezier-transition: cubic-bezier(0.85, 0.01, 0.4, 1);

.nav-link {
  position: relative;
  transform: translate3d(0, 100%, 0);
  transition: transform 1s cubic-bezier(0.85, 0.01, 0.4, 1);

  &:after {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.65s cubic-bezier(0.5, 0, 0, 1);
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.menu-background {
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 1s $bezier-transition;
  will-change: transform;
}

.menu-dot {
  transition: background 1s $bezier-transition;
}

.menu-words {
  transition: transform 1s $bezier-transition;
  span {
    transition: color 1s $bezier-transition;
  }
}

.menu-nav {
  transition: visibility 0.8s $bezier-transition;
}

.is-open {
  .menu-background {
    transform: scaleY(1);
  }
  .menu-words {
    transform: translate3d(0, -50%, 0);
  }
  .nav-link {
    transform: translate3d(0, -0, 0);
  }
  @for $i from 1 to 10 {
    ul li:nth-child(#{$i}) .nav-link {
      transition-delay: $i * 0.25s;
    }
  }
}
