@import "./scss/variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Arial Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Regular"), url("./assets/fonts/ARIAL.woff") format("woff");
}

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("ttf");
}

@layer base {
  html {
    font-family: "Arial Regular";
    color: $primary-text;
    -webkit-tap-highlight-color: transparent;
  }
}

.container {
  max-width: 100%;

  @media screen and (min-width: 1112px) {
    width: 100%;
    max-width: calc(($gutter * 2) + 1080px);
    margin: 0 auto;
  }
}

[class*="w-column-"] {
  padding-left: $gutter;
  padding-right: $gutter;
}

.rich-description {
  a {
    color: $primary-color;
    transition: color 150ms cubic-bezier(0.77, 0, 0.175, 1);

    &:hover {
      color: $primary-text;
    }
  }
}
